<template>
  <div>
    <div class="app-container">
      <div class="outer">
        <p style="font-size: 16px;">
          根据下面的步骤，扫描二维码，建立您的微信号与您平台账号的绑定关系，便于接收发货等消息通知
        </p>
        <div class="code-box">
          <div>
            <p style="font-size: 14px;">
              第一步，微信扫描下方二维码，建立与您平台账号的绑定关系
            </p>
            <img
              :src="imgUrl2"
              alt="二维码2"
            />
          </div>
          <div>
            <p style="font-size: 14px;">
              第二步，微信扫描下方二维码，关注网上农博公众号，便于微信接收发货等消息通知
            </p>
            <img
              :src="imgUrl1"
              alt="二维码1"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'QrCode',
  data() {
    return {
      imgUrl1: '',
      imgUrl2: ''
    }
  },
  mounted() {
    this.getImgUrl()
  },
  methods: {
    getImgUrl() {
      this.$axios.post(this.$api.Msg.get_qr_code).then((res) => {
        let r = res
        if (r.error === 0) {
          this.imgUrl1 = r.data.first_qrcode_url
          this.imgUrl2 = r.data.second_qrcode_url
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.code-box {
  margin: 50px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  img {
    width: 300px;
    height: auto;
    margin-top: 20px;
  }
}
</style>
