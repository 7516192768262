var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "app-container" }, [
      _c("div", { staticClass: "outer" }, [
        _c("p", { staticStyle: { "font-size": "16px" } }, [
          _vm._v(
            " 根据下面的步骤，扫描二维码，建立您的微信号与您平台账号的绑定关系，便于接收发货等消息通知 "
          ),
        ]),
        _c("div", { staticClass: "code-box" }, [
          _c("div", [
            _c("p", { staticStyle: { "font-size": "14px" } }, [
              _vm._v(
                " 第一步，微信扫描下方二维码，建立与您平台账号的绑定关系 "
              ),
            ]),
            _c("img", { attrs: { src: _vm.imgUrl2, alt: "二维码2" } }),
          ]),
          _c("div", [
            _c("p", { staticStyle: { "font-size": "14px" } }, [
              _vm._v(
                " 第二步，微信扫描下方二维码，关注网上农博公众号，便于微信接收发货等消息通知 "
              ),
            ]),
            _c("img", { attrs: { src: _vm.imgUrl1, alt: "二维码1" } }),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }